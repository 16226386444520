.small-pie-heading-container {
  display: block;
  margin-bottom: -16px;
}

.graph-title-container {
  display: block;
  margin-top: -12px;
}

.small-pie-heading {
  display: inline-block;
  width: 20%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: center;
  font-weight: 500;
  &:first-child {
    @include directify($directions) {
      #{directed('margin-left')}: 10%;
    }
  }
  &:last-child{
    @include directify($directions) {
      #{directed('margin-right')}: 10%;
    }
  }
}
