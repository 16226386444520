.loading-btn {
  display: flex;
  align-items: center;
  margin-top: 8px;
  svg {
    margin: 0;
  }
  .icon__lg {
    width: 24px !important;
    height: 24px !important;
  }
}
