.sidebar {
  top: 0;
	z-index: 96;
  height: 100vh;
  width: 240px;
  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 44px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  &:not(.sidebar-filter) {
    position: fixed;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }
  &.sidebar-filter {
    position: fixed;
    z-index: 99;
    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  }

	&.sidebar-mobile {
		padding-top: 0px;
	}

  @include themify($themes) {
    background: themed('colorBackground');
  }

  a {
    display: block;
  }

  &.sidebar--show {
		display: block;
		transform: translateX(0);
		
		&.sidebar-mobile {
			z-index: 200;
		}
    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__sticky-wrapper {
	position: sticky;
	top: 105px;
}

@media screen and (max-width: 576px) {
	.sidebar__sticky-wrapper {
		position: fixed;
		top: 44px;
	}
	.sidebar {
		z-index: 99;
	}
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {

  .sidebar__link {

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__scroll {
  width: 240px;
  overflow: visible !important;

  & > div {
    height: calc(100vh - 120px);
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      @include directify($directions) {
        #{directed('margin-right')}: 3px;
      }
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
	padding-top: 16px;
  height: 100%;
  overflow: visible;
  transition: all 0.3s;
  transform: translateX(0);
  &.sidebar__content-dynamic {
    padding: 16px 16px;
    padding-top: 32px;  
    @include directify($directions) {
      #{directed('padding-right')}: 32px;
    }
  }
/*
  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
*/
}

.sidebar-filter .sidebar__content-dynamic {
  transition: all 0.2s;
}

.sidebar__toggle-collapse-btn {
	font-size: 24px;
	cursor: pointer;
	position: absolute;
	@include directify($directions) {
		#{directed('left')}: calc(100% - 32px);
	}
	padding: 4px;
	top: 10px;
	display: flex;
	border: none;
	background: transparent;
	transition: all 0.3s;

	@include themify($themes) {
		color: themed('colorText');
	}

	&:hover {

		@include themify($themes) {
			background-color: themed('colorHover');
		}
	}

	svg {
		margin: auto;
		height: 24px;
		width: 24px;
		fill: #b1c3c8;
		
		transition: all 0.6s;
		@include directify($directions) {
			transform: rotateZ(#{directed('-empty')}90deg);
		}
	}
}

.sidebar--collapse:not(.sidebar-filter) .sidebar__toggle-collapse-btn {
	@include directify($directions) {
		#{directed('left')}: 2px;
        @media screen and (max-width: 576px){
          #{directed('padding-right')}: 0;
          #{directed('left')}: 8px;
        }
	}
	svg {
		@include directify($directions) {
			transform: rotateZ(#{directed('-empty')}270deg);
		}
	}
}

.sidebar__block {
  padding: 15px 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-child {
    border: none;
  }
}

.sidebar__link {
  height: 36px;
  width: 240px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    top: 0;
    height: 100%;
    width: 2px;
    background: $color-accent;
    opacity: 0;
    transition: all 0.3s;
  }

  p {
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 43px;
    }
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    text-decoration: none;

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
}

.sidebar__link-icon {
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
  font-size: 13px;
  line-height: 13px;
  color: #b1c3c8;
}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      @include directify($directions) {
        #{directed('padding-left')}: 53px;
      }

      p {
        @include directify($directions) {
          #{directed('left')}: 53px;
        }
      }
    }
  }

  @include themify($themes) {
    background-color: themed('colorHover');
  }

  .sidebar__link {
    @include directify($directions) {
      #{directed('padding-left')}: 43px;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }
  }

  .sidebar__link {
    display: block;
  }
}

.rtl-support {
  .sidebar__category-icon {
    transform: rotate(180deg);
  }
}

.sidebar__category-icon {
  position: absolute;
  @include directify($directions) {
    #{directed('right')}: 15px;
	}
	font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  color: $color-gray;

}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  @include directify($directions) {
    #{directed('margin-left')}: 5px;
  }
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {

  &.sidebar__category-wrap--open {

    .sidebar__category-icon {
      transform: rotate(90deg);
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  @include directify($directions) {
    #{directed('margin-left')}: 5px;
	}
	background: $color-red;
}


@media screen and (min-width: 0px /*576px*/) {

  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      @include directify($directions) {
        transform: translateX(calc(#{directed('-empty')}100% #{directed('-')} 20px));
			}
			&.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {

      @media screen and (max-width: 576px) {
        &:not(.sidebar-filter) {
          @include directify($directions) {
            #{directed('left')}: -12px;
          }
        }
      }

      &:not(.sidebar-filter) {
        width: 36px;
        overflow: visible;
      }
      &.sidebar-filter {
        width: 0;
        overflow: hidden;
      }

      .sidebar__scroll, .sidebar__content {
        width: 36px;
        overflow: visible !important;
        transition: width 2.3s;
			}
			
			&:not(.sidebar-filter) .sidebar__content-dynamic {
        @include directify($directions) {
          transform: translateX(#{directed('-empty')}220px);
        }
      }
      &.sidebar-filter .sidebar__content-dynamic {
        @include directify($directions) {
          transform: translateX(#{directed('+empty')}220px);
        }
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .sidebar__category-wrap {

        &:hover {

          .sidebar__category {
            width: 240px;

            @include themify($themes) {
              background: themed('colorHover');
            }

            &:before {
              opacity: 1;
            }
          }

          .sidebar__submenu-wrap {
            width: 185px;
          }
        }
      }

      .sidebar__submenu-wrap {
        position: absolute;
        @include directify($directions) {
          #{directed('left')}: 55px;
        }
        width: 0;
        transition: 0.3s;
        overflow: hidden;

        .sidebar__link {
          width: 185px;
          @include directify($directions) {
            #{directed('padding-left')}: 15px;
          }

          p {
            position: relative;
            @include directify($directions) {
              #{directed('left')}: 0px;
            }
            animation: none;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          @include directify($directions) {
            #{directed('left')}: 0px;
          }

          .sidebar__link {
            @include directify($directions) {
              #{directed('padding-left')}: 30px;
            }
          }
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: 55px;
        background-color: transparent;

        p {
          position: absolute;
          @include directify($directions) {
            #{directed('left')}: 70px;
          }
          width: 160px;
        }

        &:hover {
          width: 240px;

          @include themify($themes) {
            background: themed('colorHover');
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {
        @include directify($directions) {
          #{directed('margin-right')}: 188px;
        }
      }

    }
  }
/*
  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
	}
*/

}

@media screen and (min-width: 1050px) {

  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}

.sidebar-details {
  > * {
    margin: 8px 0;
  }
  a { 
    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
  }
  .sidebar-details__block {
    margin: 12px 0;
    .sidebar-details__block-title {
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .sidebar-details__block-content {
      margin: 0;
    }
  }
}
