.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  @include directify($directions) {
    #{directed('padding-left')}: 5px;
  }
  padding-top: 120px;
  min-height: 100vh;
  transition: padding-left 0.3s padding-right 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (max-width: 576px) {
    @include directify($directions) {
      #{directed('padding-left')}: 0px;
    }
  }
  // @media screen and (min-width: 576px) {
  //   @include directify($directions) {
  //     #{directed('padding-left')}: 250px;
  //   }
  // }
}

.container__with-filter {
  @include directify($directions) {
    #{directed('padding-right')}: 15px;
  }
  @media screen and (max-width: 576px) {
    overflow: hidden;
  }
  @media screen and (min-width: 768px) {
    @include directify($directions) {
      #{directed('padding-right')}: 265px;
    }
  }
}

.layout {
  &.layout--collapse {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 44px;
        }
        .topbar.topbar--secondary {
          @include directify($directions) {
            transform: translateX(#{directed('+empty')}32px);
            width: calc(100% - 32px);
          }
        }
      }
    }
  }

  &.layout--filter-collapse {
    & + .container__wrap .container__with-filter {
      @media screen and (min-width: 768px) {
        @include directify($directions) {
          #{directed('padding-right')}: 20px;
        }
      }
    }
  }

  &.layout--top-navigation {
    & + .container__wrap {
      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 0px;
        }
      }
    }
  }
}
.topbar--secondary {
  & + .container {
    margin-top: 44px;
  }
}
