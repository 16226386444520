.rtl-support{
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}
input.phone-input {
  height: 38px;
  margin-bottom: 2px;
}
