.task-tree__item-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .task-tree__item-head-title-block {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  button {
    font-size: 24px;
    cursor: pointer;
    padding: 0px 4px;
    border: none;
    background: transparent;
    transition: all 0.3s;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
    svg {
      margin: auto;
      height: 16px;
      width: 16px;
      fill: #b1c3c8;
    }
    &.task-tree__item-head-toggle-btn svg {
      margin: auto;
      height: 24px;
      width: 24px;
      fill: #b1c3c8;

      transition: all 0.6s;
      @include directify($directions) {
        transform: rotateZ(#{directed('-empty')}90deg);
      }
    }
  }


  &.task-tree__item-head--open {
    .task-tree__item-head-toggle-btn {
      @include directify($directions) {
        #{directed('left')}: 2px;
      }
      svg {
        @include directify($directions) {
          transform: rotateZ(0deg);
        }
      }
    }
  }

  a {
    padding: 6px 4px 4px;
    &:hover {

      @include themify($themes) {
        background-color: themed('colorHover');
      }
    }
    h5, h6 {
      font-weight: 500;
    }
  }
}

.tasks-page {

  .tasks-page__view-mode-switch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 28px;
    h4 {
      font-weight: 500;
      font-size: 16px;
      margin-left: 8px;
      margin-right: 8px;
    }
    .btn svg {
      margin: 0;
    }
  }

  .tasks-page__filter-row {
    align-items: stretch;
    margin-bottom: 16px;

    > div {
      min-height: 60px;
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;
      > * {
        flex: 3;
        > .search-box__search-field {
          height: 100%;
          border-radius: 4px;
          background-color: transparent;
        }
        input:hover {
          border-color: $color-accent;
        }
      }
      .date-picker {
        height: 100%;
        .react-datepicker-wrapper {
          height: 100%;
          .react-datepicker__input-container {
            height: 100%;
            .form__form-group-datepicker {
              height: 100%;
              width: 100%;
              padding: 2px 8px;
              border-radius: 4px;
              border-width: 1px;
              border-style: solid;
            }
          }
        }
        .react-datepicker-popper {
          @include directify($directions) {
            #{directed('left')}: -150px !important;
          }
          .react-datepicker__triangle {
            left: auto;
            @include directify($directions) {
              #{directed('right')}: 50px;
            }
          }
          z-index: 99;
        }
      }
      > .title {
        flex: 1;
      }
    }
  }

  .tasks-page__add-new-btn {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    svg {
      margin: 0 8px 0 0;
    }
  }


  .react-grid-Cell {
    .react-grid-Cell__value {
      .btn-sm {
        padding: 5px 8px;
        margin-bottom: 0;
      }
    }
  }
}


.tasks-page__form {
  .form__form-group {
    .btn {
      margin-bottom: 0;
      display: flex;
    }
  }
}

.tasks-page {
  margin-bottom: 15px;
}

.tasks-page__checkbox {

  input:checked ~ .tasks-page__checkbox-label {
    color: $color-additional;
    text-decoration: line-through;
  }
  .tasks-page__checkbox-label {
    @include directify($directions) {
      text-align: #{directed('left')};
    }
  }
}

.tasks-page {

  .card {
    padding-bottom: 20px;
  }

  .tasks-page__divider {
    padding-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tasks-page__divider-title {
    text-transform: uppercase;
    color: rgba(92, 104, 156, 0.6);
    margin: 0 10px;
  }

  .tasks-page__divider-line {
    height: 1px;
    background-color: rgba(92, 104, 156, 0.6);
    opacity: 0.15;
    flex-grow: 1;
  }

  .tasks-page__item {
    display: flex;

    span, li, p, b {
      @include directify($directions) {
        #{directed('margin-right')}: 5px;
      }
      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .tasks-page__info {
      flex-grow: 1;

      .tasks-page__header {
        display: flex;
        margin-bottom: 10px;

        h3 {
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
        }

        .tasks-page__additional {
          @include directify($directions) {
            #{directed('margin-left')}: auto;
          }
          display: flex;
        }

        .tasks-page__priority-indicator {
          background-color: $color-accent;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          align-self: center;
          flex-shrink: 0;

          &.low {
            background-color: $color-accent;
          }

          &.medium {
            background-color: $color-yellow;
          }

          &.high {
            background-color: $color-red;
          }
        }

        .tasks-page__due-date, .tasks-page__priority {
          padding: 0 5px;

          @include themify($themes) {
            background-color: themed('colorHover');
          }
        }

        .tasks-page__due-date {
          @include directify($directions) {
            #{directed('margin-right')}: 15px;
          }
        }

        .tasks-page__priority {
          @include directify($directions) {
            #{directed('margin-right')}: 5px;
          }
          @include themify($themes) {
            color: themed('colorText');
          }
        }
      }

      .tasks-page__content {
        @include directify($directions) {
          text-align: directed('left');
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .tasks-page__description {
          margin-right: 4px;
          margin-top: 4px;
          margin-bottom: 8px;
          @include themify($themes) {
            color: themed('colorText');
          }
          max-width: 85%;
          ul {
            padding-inline-start: 0;
            list-style-type: none;
          }
        }

        .tasks-page__details {
          display: flex;
          flex-direction: column;
        }

        .tasks-page__delete-btn {
          border: none;
          position: relative;
          color: #999999;
          cursor: pointer;
          align-self: flex-end;
          padding: 5px;
          @include themify($themes) {
            background-color: themed('colorHover');
          }

          svg {
            height: 16px;
            width: 16px;
            fill: $color-additional;
          }

          &:hover {
            color: $color-red-hover;

            svg {
              fill: $color-red-hover;
            }
          }
        }
      }

    }

  }

  .tasks-page__item-comleted {

    h3 {
      text-decoration: line-through;
    }

    h3, .tasks-page__content {
      color: #999999;
    }
  }

  .tasks-page__input-new {
    text-align: center;
  }

  .tasks-page__sidebar {
    background-color: $color-gold;
    min-height: 710px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;

    .tasks-page_sidebar-image {
      width: 130px;
      margin-bottom: 20px;
    }

    .tasks-page__date-filter,
    .tasks-page__priority-filter,
    .tasks-page__select-filter,
    .tasks-page__search-filter {
      align-self: flex-start;
      @include directify($directions) {
        text-align: directed('left');
      }
      margin-bottom: 20px;

      .title {
        font-size: 12px;
        margin-bottom: 15px;
        color: white;
        opacity: 0.6;
      }
      .react-datepicker__input-container {
        input {
          width: 100%;
        }
      }
      .react-datepicker-popper {
        @include directify($directions) {
          #{directed('left')}: -120px !important;
        }
        .react-datepicker__triangle {
          left: auto;
          @include directify($directions) {
            #{directed('right')}: 50px;
          }
        }
      }
    }
    .tasks-page__select-filter, .tasks-page__date-filter, .tasks-page__search-filter {
      align-self: stretch;
      max-width: 200px;
    }

    .tasks-page__date-filter-list,
    .tasks-page__priority-filter-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li:not(:last-child) {
        margin-bottom: 10px;
      }

      label {
        margin: 0;
        color: white;
        font-size: 12px;
      }
    }

    .tasks-page__filter-radio {
      display: none;
    }

    .tasks-page__filter-radio + label {
      position: relative;
      @include directify($directions) {
        #{directed('padding-right')}: 0px;
        #{directed('padding-left')}: 25px;
      }
      padding-top: 0px;
      padding-bottom: 0px;
      cursor: pointer;
    }

    .tasks-page__filter-radio + label:before {
      content: "";
      position: absolute;
      @include directify($directions) {
        #{directed('left')}: 0px;
      }
      width: 15px;
      height: 15px;
      border: 1px solid #fafbfe;
      border-radius: 50%;
    }

    .tasks-page__filter-radio + label:after {
      content: "";
      position: absolute;
      top: 3px;
      @include directify($directions) {
        #{directed('left')}: 3px;
      }
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #fafbfe;
      opacity: 0;
      transition: .2s;
    }

    .tasks-page__filter-radio:checked + label:after {
      opacity: 1;
    }
  }

  .tasks-page__btn-add-new {
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    min-width: 130px;
    background-color: $color-gold;
    margin: 0;
    border: 2px solid white;
    border-radius: 100px;
    margin-bottom: 20px;

    &:before {
      background-color: lighten($color-gold, 10%);
    }

    &:hover {
      color: white;
    }

    svg {
      height: 16px;
      width: 16px;
      margin: 0;
      fill: white;
    }
  }

  .tasks-page__label-checkbox {
    position: relative;
    @include directify($directions) {
      #{directed('margin-right')}: 10px;
    }
    width: 25px;
    flex-shrink: 0;

    .tasks-page__complete-toggle {
      display: none;
    }

    .tasks-page__complete-toggle + .checkbox-indicator {
      position: absolute;
      top: 0;
      @include directify($directions) {
        #{directed('left')}: 0px;
      }

      width: 19px;
      height: 18px;

      border: 1px solid;
      border-color: #dddddd;
      border-radius: 3px;
      display: block;
      cursor: pointer;
    }

    .tasks-page__complete-toggle:checked + .checkbox-indicator {
      border-color: $color-accent;
      background-color: $color-accent;
    }

    .tasks-page__complete-toggle + .checkbox-indicator::before,
    .tasks-page__complete-toggle + .checkbox-indicator::after {
      content: "";
      position: absolute;
      opacity: 0;
    }

    .tasks-page__complete-toggle:checked + .checkbox-indicator::before,
    .tasks-page__complete-toggle:checked + .checkbox-indicator::after {
      content: "";

      position: absolute;
      top: 8px;
      @include directify($directions) {
        #{directed('left')}: 1px;
      }

      width: 15px;
      height: 2px;

      background-color: white;
      opacity: 1;
      transition: 0.2s;
    }

    .tasks-page__complete-toggle:checked + .checkbox-indicator::before {
      transform: rotate(45deg);
      @include directify($directions) {
        #{directed('left')}: 2px;
      }
      top: 9px;
      height: 1px;
      width: 6px;
    }

    .tasks-page__complete-toggle:checked + .checkbox-indicator::after {
      transform: rotate(-45deg);
      @include directify($directions) {
        #{directed('left')}: 6px;
      }
      top: 7px;
      height: 1px;
      width: 10px;
    }
  }

}

.tasks-page__add-modal {
  max-width: 600px;

  .modal-content {
    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }

  .modal__footer {
    justify-content: flex-start;
  }

  .form__form-group {
    @include directify($directions) {
      text-align: directed('left');
    }

    .form__form-group-field input,
    .form__form-group-field textarea {

      @include themify($themes) {
        border: 1px solid themed('colorFieldsBorder');
        color: themed('colorText');
      }
    }

    .form__form-group-field.priority {
      display: block;
    }
  }

}
