@import '~react-phone-input-2/dist/style.css';

.react-tel-input {
  width: 100%;
  &> input[type=tel], &> input[type=text] {
    @include directify($directions) {
      #{directed('margin-left')}: 40px;
    }
  }
  &> input[type=tel], &> input[type=text],
  .flag-dropdown .country-list .search input.search-box {
    width: calc(100% - 40px);
    border-radius: 0px;
    padding: 5px 10px;
    font-size: 12px;
    transition: border 0.3s;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
      color: themed('colorText');
    }

    &[disabled] {

      @include themify($themes) {
        background: themed('colorBackgroundBody');
      }

      &:focus, &:active {

        @include themify($themes) {
          border-color: themed('colorBorder');
        }
      }
    }

    &:focus, &:active {
      outline: none;
      border-color: $color-accent;
      box-shadow: none;
    }
  }

  .flag-dropdown {
    border-radius: 0;
    &.open-dropdown {
      border-radius: 0;
    }
    .selected-flag {
      padding-left: 0;
      @include directify($directions) {
        #{directed('padding-left')}: 8px;
      }
      .arrow {
        @include directify($directions) {
          left: unset;
          #{directed('left')}: 20px;
        }
      }
    }

    @include themify($themes) {
      background: themed('colorFieldsBorder');
      border: 1px solid themed('colorFieldsBorder');
    }

    &:hover, &:focus, &.open-dropdown {
      .selected-flag {
        border-radius: 0;
        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }
    }
    .country-list {
      border-radius: 0;
      border-bottom: 8px;
      box-shadow: none;
      font-size: 12px;
      animation: open 0.3s ease-in-out;

      @include themify($themes) {
        background: themed('colorBackground');
        border: 1px solid themed('colorFieldsBorder');
        border-bottom: 4px solid themed('colorBackground'); // hack add bottom padding
        box-shadow: 0 1px 1px 1px themed('colorFieldsBorder'); // hack add bottom border
      }

      .search {
        @include themify($themes) {
          background: themed('colorBackground');
        }
        display: flex;
        padding: 10px 10px 6px;
        align-items: center;
        input.search-box {
          height: 32px;
          margin-left: unset;
          @include directify($directions) {
            #{directed('margin-left')}: 8px;
          }
        }
      }

      .country {
        display: flex;
        align-items: center;
        .flag {
          margin-right: 0;
          margin-top: 0;
        }
        .country-name {
          margin: 0 6px;
          @include themify($themes) {
            color: themed('colorText');
          }
        }
        .dial-code {
          @include themify($themes) {
            color: themed('colorTextAdditional');
          }
        }

        &:hover, &:focus {
          outline: none;
          @include themify($themes) {
            background-color: themed('colorHover');
          }
        }
      }
    }
  }
}

