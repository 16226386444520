.separator {
    border-bottom: 1px solid black;
    width: 100%;
  }

.width-reducer{
  width: 10px!important;

}

.col{
  flex-basis: 0!important;
  flex-grow: 1!important;
  max-width: 100% !important;
}

.fix{
  min-height: 100vh!important;
  height: auto!important;
}

.mail-title{
  padding-right: 5px!important;
  bottom: 35px!important;
  position: absolute!important;
  font-weight: bold!important;
}

.mar-top{
  margin-top: 15px!important;
}

.add-cursor{
  cursor: pointer!important;
}

.pop-fix{
  top: 847px !important;
  left: 285px!important;
  position:absolute!important;
}

.fix-class0{
  top : 485px!important
}

.fix-class1{
  top : 525px!important
}

.fix-class2{
  top : 565px!important
}

.fix-class3{
  top : 605px!important
}

.fix-class4{
  top : 645px!important
}
.fix-class5{
  top : 685px!important
}

.fix-class6{
  top : 725px!important
}

.fix-class7{
  top : 765px!important
}

.fix-class8{
  top : 805px!important
}
.fix-class9{
  top : 845px!important
}