.material-form {
  position: relative;

  @include directify($directions) {
    text-align: directed('left');
  }

  .material-form__field {
    width: 100%;
    margin-bottom: 20px;

    & > div:before {

      @include themify($themes) {
        border-bottom-color: themed('colorFieldsBorder');
      }
    }

    & > div:hover:before {
      border-bottom: 1px solid $color-accent !important;
    }

    & > div:after {
      border-color: $color-accent;
    }

    input, textarea {
      font-size: 12px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }
    label {
      @include directify($directions) {
        #{directed('left')}: 0px;
        #{directed('right')}: auto;
      }
    }

    svg {
      @include directify($directions) {
        #{directed('left')}: auto;
        #{directed('right')}: 0;
      }

      @include themify($themes) {
        fill: themed('colorText');
      }
    }
  }

  .material-form__label {
    margin: 0;
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }

    &.required::after {
      content: ' *';
      font-size: 12px;
      color: red;
      vertical-align: top;
    }
  }

  label {
    font-size: 12px;

    @include themify($themes) {
      color: themed('colorText');
    }

    &[data-shrink="true"] {
      transform: translate(0, 1.5px) scale(0.85);
    }
  }

  .material-form__button {
    padding: 0;
    height: 28px;
    width: 28px;
    margin-top: -8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;
    position: absolute;

    @include themify($themes) {
      background: themed('colorFieldsBorder');
      border: 1px solid themed('colorFieldsBorder');
    }

    @include directify($directions) {
      #{directed('right')}: 8px;
    }

    svg {
      fill: $color-additional;
      width: 18px;
      height: 18px;
      transition: all 0.3s;
    }

    &.active {
      background: $color-accent;
      border: 1px solid $color-accent;

      svg {
        fill: #ffffff;
      }
    }
  }
}

.material-form__option {
  font-size: 13px !important;
  height: 18px !important;
}
