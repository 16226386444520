.search-box__search {
  display: flex;
  margin: auto 0;
  padding: 0;
  position: relative;
}

.search-box__search-field {
  width: 0;
  transition: all 0.3s;
  opacity: 0;
  @include directify($directions) {
    #{directed('margin-right')}: auto;
    #{directed('margin-left')}: 0px;
  }
  margin-top: auto;
  margin-bottom: auto;
  border: none;
  border-radius: 13px;
  height: 26px;
  @include directify($directions) {
    #{directed('padding-left')}: 10px;
    #{directed('padding-right')}: 30px;
  }

  @include themify($themes) {
    background-color: themed('colorBackgroundBody');
    color: themed('colorText');
  }

  &.search-box__search-field--open {
    width: 100%;
    opacity: 1;

    & + button {
      @include directify($directions) {
        #{directed('right')}: 4px;
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.search-box__search-field {
  &::placeholder {
    color: rgb(202, 193, 193);
  }

  &.search-box__search-field--open {
    width: 100%;
  }
}


.search-box__search-btn {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  border: none;
  padding: 0;
  background: transparent;
  position: absolute;
  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}


.search-box__btn {
  font-size: 18px;
  height: 100%;
  padding: 0 2px;
  cursor: pointer;
  position: absolute;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:hover {

    @include themify($themes) {
      background-color: themed('colorHover');
    }
  }

  svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
  }

  @keyframes beforePulse {
    from {
      width: 7px;
      height: 7px;
    }
    25% {
      width: 13px;
      height: 13px;
    }
    to {
      width: 7px;
      height: 7px;
    }
  }
}
